import request from '@/utils/request' // 引入封装的request.js文件
// ding -config ding.cfg -subdomain teethbackend 8888
let base_url = "https://caj.frogking.cn";


if(process.env.NODE_ENV=='development'){
    base_url = "https://caj.frogking.cn";
}else{
    if(process.env.NODE_ENV=='production'){
        base_url = "https://caj.frogking.cn";
    }else{
        base_url = "https://caj.frogking.cn";
    }
}

// login
export function user_login_api(data) {
    return request({
        url: base_url + `/user/login`,
        method: 'post',
        data
    })
}

// add a task
export function task_create(data) {
    return request({
        url: base_url + `/task/create`,
        method: 'post',
        data
    })
}

// add a file
export function file_create(data) {
    return request({
        url: base_url + `/file/create`,
        method: 'post',
        data
    })
}

// 查询任务状态
export function task_status(data) {
    return request({
        url: base_url + `/task/status/` + data.taskId,
        method: 'get',
        data
    })
}

// sts
export function task_auth(data) {
    return request({
        url: base_url + `/task/auth/`,
        method: 'get',
        data
    })
}

// 用户登录接口 通过此接口创建用户
export function user_register_api(data) {
    return request({
        url: base_url + `/user/register/`,
        method: 'post',
        data
    })
}


// 发送验证码
export function send_identify(data) {
    return request({
        url: base_url + `/user/sendIdentify/`+data.identify,
        method: 'get',
        data
    })
}

//获取目录树
export function get_menu(data) {
    return request({
        url: base_url + `/task/menu/`,
        method: 'post',
        data
    })
}

//根据task_id获取任务详情
export function get_task_detail(data) {
    return request({
        url: base_url + `/task/cajkey/`+data.id,
        method: 'get',
        data
    })
}

// count_api
export function count_api(data) {
    return request({
        url: base_url + `/task/count`,
        method: 'get',
        data
    })
}