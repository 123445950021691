<style lang="scss" scoped>
#header {
    top: 0px;
    z-index: 200;
    position: fixed;
    height: 60px;
    width: 100vw;
    background-color: rgb(255, 255, 255);
    font: bold;
    opacity: 0.9;

    .el-menu-font {
        font-size: 18px;
    }

    .image-container {
        width: 222px;

        .image {
            width: 222px;
            height: 59px;
            background: rgb(255, 255, 255);
            transition: all 0.4s;
            -moz-transition: all 0.4s;
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            background-repeat: no-repeat;
            /* 不会重复 */
            background-position: center;
            /* 是为了让图片放大的时候从中部放大，不会偏移 */
            background-size: 100% 100%;

            image-rendering: -moz-crisp-edges;
            /* Firefox */
            image-rendering: -o-crisp-edges;
            /* Opera */
            image-rendering: -webkit-optimize-contrast;
            /* Webkit (non-standard naming) */
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor;
            /* IE (non-standard property) */
        }

        .image:hover {
            background-size: 103% 103%;
        }
    }

    .menu-icon {
        float: left;
        margin: auto auto;
        margin-left: 0px;
        margin-top: 10px;
    }
}
</style>

<template>
<div>
    <div id="header">
        <div v-if="client_width >= 1300">
            <Row align="middle" type="flex" justify="center" class="code-row-bg">
                <Col span="5" :style="{
                                'border-bottom':
                                    active_menu_index == 2 ? '2px solid #2d8cf0' : '',
                            }">
                <a href="/" style="float:right;margin-right:0px;display: block;">
                    <img style="height:50px;overflow:hidden;object-fit:cover;background-color:transparent;" src="@/assets/images/logo.png" height="50px" />
                </a>
                </Col>

                <Col span="14">
                <el-menu router @select="handle_select" style="border-bottom: solid 0px #e6e6e6" class="el-menu-demo el-menu-font" mode="horizontal" background-color="rgb(255, 255, 255)" text-color="rgb(0, 0, 0)" active-text-color="#2d8cf0">
                    <Row align="middle" type="flex" justify="center" class="code-row-bg">

                        <Col span="5" :style="{
                                'border-bottom':
                                    active_menu_index == 1 ? '2px solid #2d8cf0' : '',
                            }">
                        <el-menu-item index="/">
                            <Icon type="ios-home" size=17 /><span style="margin-left:4px;font-size:17px;">首页</span></el-menu-item>
                        </Col>
                        <Col span="5">
                        <!-- <el-menu-item :style="{
                                'border-bottom':
                                    active_menu_index == 3 ? '2px solid #2d8cf0' : '',
                            }" index="/download">
                            <Icon type="md-arrow-round-down" size=17 /><span style="margin-left:4px;font-size:17px;">软件下载</span></el-menu-item> -->
                        </Col>
                        <Col span="4">
                        <!-- <el-menu-item :style="{
                                'border-bottom':
                                    active_menu_index == 4 ? '2px solid #2d8cf0' : '',
                            }" index="4">
                            <Icon type="ios-bookmarks" size=17 /><span style="margin-left:4px;font-size:17px;">使用教程</span></el-menu-item> -->
                        </Col>
                        <Col span="5">
                        <!-- <el-menu-item :style="{
                                'border-bottom':
                                    active_menu_index == 5 ? '2px solid #2d8cf0' : '',
                            }" index="5">
                            <Icon type="md-pricetag" size=17 /><span style="margin-left:4px;font-size:17px;">常见问题</span></el-menu-item> -->
                        </Col>
                        <Col span="4">
                        <!-- <el-menu-item style="padding: 0px 0px" index="login">
                            <div v-if="!logined">
                                <Icon type="md-person-add" size=17 /><span style="margin-left:4px;font-size:17px;">注册 | 登录</span></div>
                            <div v-else>
                                <el-submenu index="2" class="elmenuclass">
                                    <template slot="title">
                                        <Icon type="md-person" size=17 /><span style="margin-left:4px;font-size:17px;">个人中心</span></template>
                                    <el-menu-item index="2-1" @click="logout">
                                        <Icon type="md-exit" size=17 /><span style="margin-left:4px;font-size:17px;">注销</span></el-menu-item>
                                </el-submenu>
                            </div>
                        </el-menu-item> -->
                        </Col>
                    </Row>
                </el-menu>
                </Col>
                <Col span="5">
                </Col>
            </Row>
        </div>
        <div v-else>
            <Menu mode="horizontal" active-name="1">
                <Submenu name="3">
                    <template slot="title">
                        <div class="menu-icon" @click="open_menu">
                            <Icon type="md-menu" size="38" />
                        </div>
                    </template>
                    <MenuItem name="2-1">首页</MenuItem>
                </Submenu>
            </Menu>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            active_menu_index: 1,
            logined: false,
            client_width: null,
            drawer: false,
        };
    },

    mounted() {
        var that = this;
        this.client_width = document.body.clientWidth;
        window.onresize = function () {
            that.client_width = document.documentElement.clientWidth;
            console.log("宽度", document.documentElement.clientWidth);
            console.log("高度", document.documentElement.clientHeight);
        };
        if (
            localStorage.getItem("token") != "null" &&
            localStorage.getItem("token") != null
        ) {
            this.logined = true;
        } else {
            this.logined = false;
        }

        window.addEventListener("setItem", (e) => {
            console.log(e);
            if (
                localStorage.getItem("token") != "null" &&
                localStorage.getItem("token") != null
            ) {
                this.logined = true;
            } else {
                this.logined = false;
            }
            if (
                localStorage.getItem("menu") != "null" &&
                localStorage.getItem("menu") != null
            ) {
                this.active_menu_index = parseInt(localStorage.getItem("menu"));
            }
        });
    },
    watch: {
        $router(to, from) {
            //监听路由是否变化
            if (to.query.type != from.query.type) {
                console.log(to);
            }
        },
        $route(to, from) {
            //监听路由是否变化
            if (to.query.type != from.query.type) {
                console.log(to);
            }
        },
    },
    methods: {
        open_menu() {
            this.drawer = !this.drawer;
        },
        //fix me: 这样写跳转函数太多了，应该根据菜单ID直接跳转
        go_to_login() {
            //带参数跳转
            this.$router.push({
                path: "/login",
                query: {},
            });
        },
        go_to_index() {
            //带参数跳转
            this.$router.push({
                path: "/",
                query: {},
            });
        },
        go_to_aifalse() {
            //带参数跳转
            this.$router.push({
                path: "/aifalse/submit",
                query: {},
            });
        },
        go_to_docs() {
            //带参数跳转
            this.$router.push({
                path: "/show",
                query: {},
            });
        },
        handle_select(menu) {
            console.log(menu);
            this.active_menu_index = menu;
        },
        logout() {
            localStorage.removeItem("token");
            this.logined = false;
            this.$router.push({
                path: "/login",
                query: {},
            });
        },
    },
};
</script>
