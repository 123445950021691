<template>
<div>
<Header></Header>
<div class="login-form">
    <Form ref="form_inline" :model="form_inline" class="form" :rules="ruleInline" :label-width="120">
        <h3 class="h3">注册</h3>
        <small class="help-block">CAJ转PDF | CAJ在线预览 | CAJ浏览器</small>
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-addon">
                    <Icon size="18" type="md-contact" />
                </div>
                <Input id="phone_input" class="form-control" style="width: 59%;float:left;" type="text" v-model="form_inline.phone_number" placeholder="手机号码" />
                <Button class="btn btn-success" :disabled="code_button_disabled" style="width:39%;float:right;" @click="send_code">发送验证码</Button>
            </div>

        </div>

        <div class="form-group">
            <div class="input-group">
                <div class="input-group-addon">
                    <Icon size="18" type="md-lock" />
                </div>
                <Input type="password" class="form-control" v-model="form_inline.password" placeholder="密码" />
            </div>
        </div>

        <div class="form-group">
            <div class="input-group">
                <div class="input-group-addon">
                    <Icon size="18" type="md-checkmark-circle" />
                </div>
                <Input class="form-control" type="text" v-model="form_inline.identify" placeholder="验证码" />
            </div>
        </div>

        <div class="form-group mgt-15px">
            <div style="height: 10px"></div>
            <Button class="btn btn-success" style="width: 100%" @click="register">立即注册</Button>
        </div>

        <div class="form-group">
            <div class="help-block">
                <span>已有账号？ <a href="#/login" title="" class="tooltips text-primary">立即登录</a></span>
            </div>
        </div>

    </Form>
</div>
</div>
</template>

<script>
import Header from '@/components/header/header.vue';
import {
    user_register_api,
    send_identify,
    user_login_api
} from "@/api/api";
export default {
    components: {Header},
    data() {
        return {
            form_inline: {
                phone_number: "",
                password: "",
                identify: "",
            },
            ruleInline: {
                user: [{
                    required: true,
                    message: "请填写用户名",
                    trigger: "blur",
                }, ],
                password: [{
                    required: true,
                    message: "请填写密码",
                    trigger: "blur",
                }, ],
                identify: [{
                    required: true,
                    message: "请填写验证码",
                    trigger: "blur",
                }, ],
            },
            identifyCodes: "0123456789",
            codeImage: "",
            uuid: "",
            loading: false,
            login_type: '0',
            code_button_disabled: false
        };
    },

    mounted() {
        if (localStorage.getItem("token") != null) {
            this.$router.push({
                path: "/",
                query: {},
            });
        }
    },

    methods: {
        async send_code() {
            var that = this;
            console.log(this);
            console.log(that.form_inline.phone_number);
            const identify_info = await send_identify({
                identify: that.form_inline.phone_number
            });
            console.log(identify_info);
            if (identify_info.status == '200') {
                that.$Message.success(identify_info.msg);
            } else {
                that.$Message.error(identify_info.msg);
            }
        },
        async register() {
            var that = this;
            const creat_info = await user_register_api({
                userName: that.form_inline.phone_number,
                passWord: that.form_inline.password,
                identigyCode: that.form_inline.identify
            });
            if (creat_info.status == '200') {
                that.$Message.success(creat_info.msg);
                //注册成功后自动登录
                that.$Message.success("即将自动登录");
                const login_info = await user_login_api({
                    userName: that.form_inline.phone_number,
                    passWord: that.form_inline.password
                });
                if (login_info.status == '200') {
                    that.$Message.success(login_info.msg);
                    //Token保存
                    localStorage.setItem("token", login_info.token);
                    //跳转页面
                    that.$router.replace({
                        path: "/",
                        query: {},
                    });
                } else {
                    that.$Message.error(login_info.msg);
                }
            } else {
                that.$Message.error(creat_info.msg);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.login-form {
    width: 400px;
    padding: 5px 15px 5px 15px;
    margin: 120px auto;
    /* 上下间距160px，左右自动居中*/
    background-color: #ffffff;
    /* 透明背景色 */
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #ddd;

    /* 圆角 */
    .form {
        display: block;
        margin-top: 0em;

        .h3 {
            font-size: 20px;
            font-weight: 1800;
            margin: 15px auto;
        }

        .help-block {
            display: block;
            margin-top: 5px;
            margin-bottom: 10px;
            color: #737373;
        }

        .form-group {
            margin-bottom: 15px;

            .input-group {
                position: relative;
                display: table;
                border-collapse: separate;

                .input-group-addon,
                .input-group-btn {
                    width: 1%;
                    white-space: nowrap;
                    vertical-align: middle;
                }

                .input-group-addon {
                    padding: 6px 12px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1;
                    color: #555;
                    text-align: center;
                    background-color: #eee;
                    border: 1 px solid #ccc;
                    border-radius: 4px 0px 0px 4px;
                }

                .input-group-addon:first-child {
                    border-right: 0;
                }

                .input-group .form-control:first-child,
                .input-group-addon:first-child,
                .input-group-btn:first-child>.btn,
                .input-group-btn:first-child>.btn-group>.btn,
                .input-group-btn:first-child>.dropdown-toggle,
                .input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
                .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    // border-radius: 4px 0px 0px 4px;
                }

                .input-group .form-control,
                .input-group-addon,
                .input-group-btn {
                    display: table-cell;
                }

                * {
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                }

                div {
                    display: block;
                }

                .input-group .form-control {
                    position: relative;
                    z-index: 2;
                    float: left;
                    width: 100%;
                    margin-bottom: 0;
                }

                .form-control {
                    display: block;
                    width: 100%;
                    // height: 32px;
                    // padding: 6px 12px;
                    font-size: 14px;
                    line-height: 1.42857143;
                    color: #555;
                    background-color: #fff;
                    background-image: none;
                    border-radius: 0px !important;
                    //border: 1 px solid #ccc;
                    //border-radius: 0px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    -webkit-transition: border-color ease-in-out 0.15s,
                        -webkit-box-shadow ease-in-out 0.15s;
                    -o-transition: border-color ease-in-out 0.15s,
                        box-shadow ease-in-out 0.15s;
                    transition: border-color ease-in-out 0.15s,
                        box-shadow ease-in-out 0.15s;
                }

                button,
                Button,
                Input,
                input,
                select,
                textarea {
                    font-family: inherit;
                    font-size: inherit;
                    line-height: inherit;
                }

                button,
                Button,
                Input,
                input,
                optgroup,
                select,
                textarea {
                    margin: 0;
                    font: inherit;
                    color: inherit;
                }

                .ivu-input {
                    border-radius: 0px;
                }
            }

            .help-block {
                display: block;
                margin-top: 5 px;
                margin-bottom: 10 px;
                color: #737373;

                .pull-right {
                    float: right !important;
                }
            }
        }

        .captcha {
            .captcha-img {
                width: 300px;
                height: 100px;
                vertical-align: middle;
            }
        }

        .mgt-15px {
            margin-top: 15 px;

            .btn-success {
                color: #fff;
                background-color: #5cb85c;
                border-color: #4cae4c;
            }

            .btn {
                display: inline-block;
                padding: 6 px 12 px;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.42857143;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                -ms-touch-action: manipulation;
                touch-action: manipulation;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                background-image: none;
                border: 1 px solid transparent;
                border-radius: 4 px;
            }

            button,
            Button,
            input,
            select,
            textarea {
                font-family: inherit;
                font-size: inherit;
                line-height: inherit;
            }

            button,
            Button,
            html input[type="button"],
            input[type="reset"],
            input[type="submit"] {
                -webkit-appearance: button;
                cursor: pointer;
            }

            button,
            Button,
            select {
                text-transform: none;
            }

            button,
            Button {
                overflow: visible;
            }

            button,
            Button,
            input,
            optgroup,
            select,
            textarea {
                margin: 0;
                font: inherit;
                color: inherit;
            }
        }

        .small,
        small {
            font-size: 85%;
        }
    }
}

/* 标题 */
.login-title {
    color: #303133;
    text-align: center;
}
</style>
